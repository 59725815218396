import moment from 'moment';
import db from '@/firebase/init';
import firebase from 'firebase/compat/app';
import slugify from 'slugify';

function random() {
	let rand1 = Math.floor(Math.random() * 10).toString();
	let rand2 = Math.floor(Math.random() * 10).toString();
	let rand3 = Math.floor(Math.random() * 10).toString();
	let rand4 = Math.floor(Math.random() * 10).toString();
	return rand1 + rand2 + rand3 + rand4;
}

export default {
	state: {
		userDoc: null,
		user: null,
		client: null,
		slug: null,
		header: null,
		body: null,
		footer: null,
		modal: false,
		loading: false,
	},
	getters: {
		// <Modal>
		headerU: state => state.header,
		bodyU: state => state.body,
		footerU: state => state.footer,
		modalU: state => state.modal,
		//</Modal>
		loadingU: state => state.loading,
		successU: state => state.success,
		errorU: state => state.error,
		slugU: state => state.slug,
	},
	mutations: {
		addClientDetails: (state, payload) => {
			// Retrieve and set user document
			if (payload.name === 'userDoc') {
				state.userDoc = payload.value;
			}

			// Retrieve and set current user
			if (payload.name === 'user') {
				state.user = payload.value;
			}
			// Retrieve and set client user document
			else if (payload.name === 'client') {
				state.client = payload.value;
			}
		},
		updateClientAccount: (state, payload) => {
			state.client.get().then(doc => {
				if (doc.exists) {
					if (payload.firstName) {
						state.userDoc.update({
							name: payload.firstName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.lastName) {
						state.userDoc.update({
							surname: payload.lastName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.phoneNumber) {
						state.userDoc.update({
							phone: payload.phoneNumber,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.firstName) {
						state.client.update({
							name: payload.firstName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.lastName) {
						state.client.update({
							surname: payload.lastName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.phoneNumber) {
						state.client.update({
							phone: payload.phoneNumber,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.companyName) {
						state.client.update({
							companyName: payload.companyName,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.companyWebsite) {
						state.client.update({
							website: payload.companyWebsite,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.vat) {
						state.client.update({
							vat: payload.vat,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.companyCategory) {
						state.client.update({
							companyCategory: payload.companyCategory,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.companySize) {
						state.client.update({
							companySize: payload.companySize,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.industry) {
						state.client.update({
							industry: payload.industry,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.aboutMe) {
						state.client.update({
							bio: payload.aboutMe,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.image) {
						state.client.update({
							profile: payload.image,
							lastModified: moment(Date.now()).format('L'),
						});
					}

					if (payload.addressLine1) {
						state.client.update({
							addressLine1: payload.addressLine1,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.addressLine2) {
						state.client.update({
							addressLine2: payload.addressLine2,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.city) {
						state.client.update({
							city: payload.city,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.province_state) {
						state.client.update({
							province_state: payload.province_state,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.postalCode) {
						state.client.update({
							postalCode_zipCode: payload.postalCode,
							lastModified: moment(Date.now()).format('L'),
						});
					}
					if (payload.country) {
						state.client.update({
							country: payload.country,
							lastModified: moment(Date.now()).format('L'),
						});
					}
				} else {
					state.client.set({
						userId: state.user.uid,
						created: moment(Date.now()).format('L'),
						lastModified: moment(Date.now()).format('L'),
						name: payload.firstName,
						surname: payload.lastName,
						phone: payload.phoneNumber,
						companyName: payload.companyName,
						website: payload.companyWebsite,
						vat: payload.vat,
						companySize: payload.companySize,
						companyCategory: payload.companyCategory,
						industry: payload.industry,
						bio: payload.aboutMe,
						country: 'South Africa',
						accountCreated: false,
						profile: payload.image,
					});
				}
			});
		},
		modalHide: state => {
			state.error = false;
			state.modal = false;
			state.body = null;
		},
		setSlug(state, value) {
			state.slug = value;
		},
		setLoading(state, value) {
			state.loading = value;
		},
		showModal(state, payload) {
			state.modal = true;
			state.header = payload.header;
			state.body = payload.body;
			state.footer = payload.footer;
			state.error = payload.error;
		},
		hideModal(state) {
			state.modal = false;
		},
	},
	actions: {
		async registerInvitedClient(context, payload) {
			context.commit('setLoading', true);
			if (payload.email && payload.password && payload.clientAlias && payload.firstName && payload.surname) {
				const snapshot = await db.collection('clients').doc(payload.clientAlias).collection('invitedClients').where('email', '==', payload.email).get();
				if (!snapshot.empty) {
					snapshot.docs.forEach(async doc => {
						const slug = slugify(payload.firstName + ' ' + payload.surname + ' ' + random(), {
							replacement: '-',
							remove: /[$*_+~.()'"!\-:@]/g,
							lower: true,
						});

						try {
							const result = await firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password);
							await db
								.collection('users')
								.doc(slug)
								.set({
									userId: result.user.uid,
									created: moment(Date.now()).format('L'),
									lastModified: moment(Date.now()).format('L'),
									name: payload.firstName,
									surname: payload.surname,
									phone: '',
									email: payload.email,
									user: 'client',
									terms_and_conditions: true,
									alias: slug,
									howDidYouHear: '',
									otherOption: '',
									clientAlias: payload.clientAlias,
									accessType: 'invitedClient',
								});

							await firebase.auth().currentUser.sendEmailVerification();
							context.commit('setLoading', false);
							context.commit('showModal', {
								header: 'Verify Your Email!',
								body: 'Awesome, you have been successfully registered! Please check your inbox and verify your email.',
								footer: 'Got it',
								error: true,
							});
						} catch (error) {
							context.commit('setLoading', false);
							context.commit('showModal', {
								header: 'Whoops!',
								body: error.message,
								footer: 'Got it',
								error: true,
							});
						}
					});
				} else {
					context.commit('setLoading', false);
					context.commit('showModal', {
						header: 'Whoops!',
						body: 'Looks like we do not have your details, please contact your administrator to get an invitation to the workspace!',
						footer: 'Got it',
						error: true,
					});
				}
			}
		},
		updateClientAccount: (context, payload) => {
			context.commit('updateClientAccount', payload);
		},
		addClientDetails: (context, payload) => {
			context.commit('addClientDetails', payload);
		},
		modalHide: context => {
			context.commit('modalHide');
		},
	},
};
